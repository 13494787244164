@import '../../colors.css';


.button {
    width: 160px;
    height: 25px;
    border-radius: 5px;
    border: 2px solid var(--secondary-button);
    background-color: var(--secondary-button);
}

.button:active {
    border: 2px solid var(--secondary-button);
}

@media only screen and (max-width: 1024px) {
    .button-text {
        display: none;
    }
}