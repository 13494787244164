
@import '../../colors.css';


.panel-background {
    height: 100%;
    background-color: var(--main-color);
    text-align: center;
}

.pic-profile {
    max-width: 250px;
    max-height: 250px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

.img-profile {
    max-width: 100%;
    max-height: 100%;
}

.title-details-profile {
    text-align: center;
}

.details-profile {
    text-align: left;
    padding: 15% 20% 20% 20%;
}