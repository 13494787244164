@import '../../colors.css';


.btn {
    width: 160px;
    height: 25px;
    border-radius: 5px;
    border: 2px solid lightblue;
    background-color: white;
}

@media only screen and (max-width: 1024px) {
    .button-text {
        display: none;
    }
}