@import '../../colors.css';

.profile-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}


.sections {
    width: 100%;
    flex-basis: 65%;
}

.panel {
    background-color: var(--main-color);
    height: 100vh;
}


@media only screen and (min-width: 600px) {
    .profile-container {
        flex-direction: row;
    }
    .panel {
        width: 25%;
    }
    .sections {
        width: 75%;
    }
}

hr.line {
    border: 1px solid var(--main-color);
  }