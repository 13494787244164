@import '../../colors.css';


.submitButton {
    width: 60px;
    height: 25px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--main-color);
}

.button:active {
    border: 2px solid var(--secondary-button);
}
