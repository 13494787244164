
// source = https://codepen.io/josh_vogt/pen/EaaZbP

.worko-tabs {
    margin: 20px;
  	width: 80%;
  
    .state {
      position: absolute;
      left: -10000px;
    }
  
    .flex-tabs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
        
        .tab{
          flex-grow: 1;
          max-height: 40px;
        }
      
        .panel-tab {
          background-color: #fff;
          padding: 20px;
          min-height: 300px;
          display: none;
          width: 100%;
          flex-basis: auto;
      }
    }

    .tab {
      display: inline-block;
      padding: 10px;
      vertical-align: top;
      background-color: #eee;
      cursor: hand;
      cursor: pointer;
      border-left: 10px solid #ccc;
      
        &:hover{
          background-color: #fff;
        }
    }  
}

#tab-one:checked ~ .tabs #tab-one-label,
#tab-two:checked ~ .tabs #tab-two-label,
#tab-three:checked ~ .tabs #tab-three-label,
#tab-four:checked ~ .tabs #tab-four-label{
    background-color: #fff;
    cursor: default;
    border-left-color: var(--main-color);
}

#tab-one:checked ~ .tabs #tab-one-panel,
#tab-two:checked ~ .tabs #tab-two-panel,
#tab-three:checked ~ .tabs #tab-three-panel,
#tab-four:checked ~ .tabs #tab-four-panel{
    display: block;
}

@media (max-width: 600px){
  .flex-tabs{
    flex-direction: column;
    
    .tab{
      background: #fff;
      border-bottom: 1px solid #ccc;
      
        &:last-of-type{
          border-bottom: none;
        }
    }
    
    #tab-one-label{order:1;}
    #tab-two-label{order: 3;}
    #tab-three-label{order: 5;};
    #tab-four-label{order: 7;};
    
    #tab-one-panel{order: 2;}
    #tab-two-panel{order: 4;}
    #tab-three-panel{order: 6;}
    #tab-four-panel{ order: 8;}
  }
  
    #tab-one:checked ~ .tabs #tab-one-label,
    #tab-two:checked ~ .tabs #tab-two-label,
    #tab-three:checked ~ .tabs #tab-three-label,
    #tab-four:checked ~ .tabs #tab-four-label{
      border-bottom: none;
    }
  
  #tab-one:checked ~ .tabs #tab-one-panel,
  #tab-two:checked ~ .tabs #tab-two-panel,
  #tab-three:checked ~ .tabs #tab-three-panel,
  #tab-four:checked ~ .tabs #tab-four-panel{
    border-bottom: 1px solid #ccc;
  }
}

// Section's Forms


.section-content {
  width: 100%;
}

.section-content h3 {
  margin-left: 10%;
}

.imput-content {
  display: flex;
  justify-content: space-between;
  margin: 10px 10% 0 10%;
}


.flex-item-1 {
  flex: 1;
}

.flex-item-2 {
  flex: 2;
}


.pic-cv {
  display: flex;
  max-width: 50px;
  max-height: 50px;
  margin-left: 10%;
}

.img-cv {
  max-width: 100%;
  max-height: 100%;
  margin-right: 10%;
}

.title-cv {
  font-size: small;
  margin-left: 10%;
}