@import '../../colors.css';

.user-create-container {
    background-image: none;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.mini-panel {
    margin: 0 0 0 20%;
    background-color: var(--main-color);
    height: 60vh;
    width: 30vh;
    font-size: 0.5em; 
}

@media only screen and (min-width: 600px) {
    .user-create-container {
        background-image: url('../../assets/img/map.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 95% 80%;
    }
    .mini-panel { 
        font-size: 1em; 
     }
}


// wizard source: https://codepen.io/danaabhi7119/pen/PooOvKO


.card {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 0 rgba(255,255,255,.04588);
	
	&-header{
		padding: 20px;
		
		& .steps{
			display: flex;
			column-count: 3;
			justify-content: center;
			align-items: center;
			
			& .step{
			 width: 100%; 
   		text-align: center; 
   		border-bottom: 1px solid blue; 
   		line-height: 0.1em;
   		margin: 10px 0 20px; 	
				& span{
					padding: 30px 36px;
					border: 1px solid blue;
					border-radius: 40px;
					background:#fff;
					box-shadow: 0px 0px 0px 0px blue;
				}
			}
			
			& .step.active{
				& span{
					background: #18acf2ff;
					color: white;
					border: 1px solid blue;
				}
			}
        }
    }}